import React, { useEffect } from 'react'
import BlogCategory from '../components/Blog/BlogCategory';
import allApiData from '../api.js';
import Layout from '../components/Layout';
import Hero from '../components/Mobileapp/hero';
import Seo from '../components/Seo';
import SeoheadData from '../siteScriptData';

function Bloge(props) {

  allApiData.removeanimation();
  useEffect(() => {
    allApiData.aosinit()
  }, []);

  return (
    <Layout>
      <Seo SeoData={SeoheadData.blogPageSeoData}></Seo>
      <div className='blog-page' >
        <Hero title="Learn about the latest trends of software development" def="" cta='Get a Quote'>
          <div className="hero-img hide-animation-768">
            <img src="../assets/img/blog/hero_img/learn-more-latest-trend-about-software-devepment.svg" className="z-index img-fluid" alt="learn-more-latest-trend-about-software-devepment" />
            <img src="../assets/img/blog/hero_img/1.svg" className="img-fluid blog-img-1" alt="learn-more-latest-trend-about-software-devepment-one" />
            <img src="../assets/img/blog/hero_img/2.svg" className="img-fluid blog-img-2" alt="learn-more-latest-trend-about-software-devepment-two" />
            <img src="../assets/img/blog/hero_img/3.svg" className="img-fluid blog-img-3" alt="learn-more-latest-trend-about-software-devepment-three" />
          </div>
          <div className="show-main-img-768">
            <img src="../assets/img/blog/hero_img/learn-more-latest-trend-about-software-devepment-mobile.svg" className="img-fluid" alt="learn-more-latest-trend-about-software-devepment-mobile" />
          </div>
        </Hero>

        <BlogCategory blogCategoryTag={props.location.state ? props.location.state.blogCategoryTag : ''} />
      </div>

    </Layout>
  )
}

export default Bloge