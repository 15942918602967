import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import { BiSearch, } from 'react-icons/bi'
import { Link } from 'gatsby';
import { Pagination } from "react-pagination-bar";
import allApidata from '../../api';
import Axios from 'axios';

let backenurl = allApidata.url;

function debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}

function BlogCategory({ blogCategoryTag }) {

    const [searchValue, setSearchValue] = useState("");
    const [allBlogData, setAllBlogData] = useState([]);
    const [totalpage, setTotalPage] = useState(0)
    const [blogCategoeries, setBlogCategories] = useState([]);
    const [noData, setNoData] = useState(false);

    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);

    const [category, setCategory] = useState('all');

    // const [totalPageBoolen, setTotalPageBoolen] = useState(false);

    let setAllBLogdata = (blogtitle, blogtype, pagenum) => {
        let bodydata = new FormData();
        bodydata.append('title', blogtitle);
        bodydata.append('type', blogtype);
        Axios.post(`${backenurl}/blogs?page=${pagenum}`, bodydata).then((_blogdata) => {
            setTotalPage(_blogdata.data.total);
            // setTotalPageBoolen(true);
            setAllBlogData(_blogdata.data.data);
        });
    }

    useEffect(() => {
        if (blogCategoryTag) {
            setCategory(blogCategoryTag);
            setAllBLogdata('', blogCategoryTag ? blogCategoryTag : '', currentPage);
        }
    }, []);

    useEffect(() => {
        allApidata.getCategories('blog').then((data) => {
            setBlogCategories(data)
        });
    }, []);

    const handleSearchFilter = (e) => {
        setSearchValue(e.target.value);
    };

    useEffect(() => {
        if (searchValue) {
            blogCategoryTag && setCategory(blogCategoryTag);
            debounce(setAllBLogdata(searchValue, blogCategoryTag ? blogCategoryTag : '', currentPage))
            // setAllBLogdata(searchValue, blogCategoryTag ? blogCategoryTag : '', currentPage)
        } else {
            blogCategoryTag && setCategory(blogCategoryTag);
            debounce(setAllBLogdata(searchValue, blogCategoryTag ? blogCategoryTag : '', currentPage))
            // setAllBLogdata(searchValue, blogCategoryTag ? blogCategoryTag : '', currentPage)
            setLoading(false)
        }
    }, [searchValue]);

    useEffect(() => {
        if (allBlogData.length === 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
        if (searchValue && allBlogData.length === 0) { setNoData(true); setLoading(false) } else { setNoData(false); setLoading(false) }
    }, [allBlogData]);

    let filterOnclick = async (e) => {
        setTotalPage(0)
        setCurrentPage(1)
        setCategory(e.target.value);
        // setTotalPageBoolen(false);
        if (e.target.value === 'all') {
            setAllBLogdata('', '', currentPage);
            setLoading(false);
        } else {
            setAllBLogdata('', e.target.value, currentPage);
            setLoading(false);
        }
    }

    let filterOnPageChange = async (_category, pageNumber) => {
        setCategory(_category);
        // setTotalPageBoolen(false);
        if (_category === 'all') {
            setAllBLogdata('', '', pageNumber);
            setLoading(false);
        } else {
            setAllBLogdata('', _category, pageNumber);
            setLoading(false);
        }
    }

    let onPageСhangeFunc = (pageNumber) => {
        if (blogCategoryTag) {
            setCategory(blogCategoryTag);
            setAllBLogdata('', blogCategoryTag ? blogCategoryTag : category, pageNumber);
        }
    }

    return (
        <div className='blog-category pb-100'>
            <Container>
                <Row>
                    <Col lg={9} md={12} className={`order-lg-0 order-2`}>
                        <Row className={allBlogData && allBlogData.length > 0 ? "justify-content-center" : ""}>
                            {
                                loading ? <>
                                    <div className="d-flex justify-content-center">
                                        <Spinner animation="border" variant="primary" />
                                    </div>
                                </> : <>
                                    {
                                        allBlogData && allBlogData.length > 0 && allBlogData.map((blog, i) => {
                                            return (
                                                <Col key={i} lg={6} md={6} id={blog.id} className="mb-4 ">
                                                    <Card className='border-0' >
                                                        <Link
                                                            to={`/blogs/${blog.tag}`}
                                                            // to={`/photos/${blog.id}`}
                                                            state={{ blogid: blog.title }}
                                                        ><img src={blog.banner_image ? blog.banner_image : "../assets/img/blog/category_img/Layer 3.png"} alt="Layer 3." /></Link>

                                                        <div className="blog-content">
                                                            <div className='d-flex  title'>
                                                                <p>
                                                                    {
                                                                        allApidata.dateConverter(blog.date && blog.date)
                                                                    }
                                                                </p>
                                                                <p>Category : <span>{blog.category.name}</span></p>
                                                            </div>
                                                            <Link
                                                                to={`/blogs/${blog.tag}`}
                                                                // to={`/photos/${blog.id}`}
                                                                state={{ blogid: blog.title }}
                                                            ><h4>{blog.title}</h4></Link>
                                                            <Link
                                                                to={`/blogs/${blog.tag}`}
                                                                // to={`/photos/${blog.id}`}
                                                                state={{ blogid: blog.title }}
                                                            ><p className='read_more'>Read More</p></Link>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            )
                                        })
                                    }
                                </>
                            }
                            {
                                noData ?
                                    <Col xl={4} lg={4} md={6} sm={12} className="mt-3 text-center" >
                                        <div className='app-box'>
                                            <img src='../assets/datanotfount.jpg' style={{ maxWidth: '100%', width: "100%" }} alt={'datanotfount'} />
                                            <div className='box-title'>
                                                <h5>{'No More Data!! Please Try another One.'}</h5>
                                            </div>
                                        </div>
                                    </Col> : ''
                            }
                        </Row>

                        {
                            totalpage && totalpage.length !== 0 ?
                                <Pagination
                                    initialPage={currentPage}
                                    // itemPerPage={pagePostsLimit}
                                    onPageСhange={(pageNumber) => { setCurrentPage(pageNumber); filterOnPageChange(category, pageNumber); onPageСhangeFunc(pageNumber) }}
                                    // onClick={onPageСhangeFunc}
                                    totalItems={totalpage && totalpage}
                                    // pageNeighbours={2}
                                    itemsPerPage={6}
                                /> : ''
                        }
                    </Col>

                    <Col lg={3} className="order-lg-2 order-0 mb-4" >
                        <div className="category-box">
                            <div className="search-box">
                                <input type="text" onChange={handleSearchFilter} value={searchValue} placeholder='Search...' />
                                <div className='serach-icon'>
                                    <BiSearch />
                                </div>
                            </div>
                            <div className="list">
                                <h5>Categories</h5>
                                <ul>
                                    <li className={(category && category.toUpperCase() === 'ALL') ? `active` : ``}> <img src="../assets/img/blog/category_img/arrow.svg" alt="arrow.svg" /> <button onClick={(e) => filterOnclick(e)} style={{
                                        background: "no-repeat",
                                        outline: "none",
                                        border: "none"
                                    }} value="all">All</button>
                                    </li>
                                    {
                                        blogCategoeries && blogCategoeries.map((title, i) => {
                                            return (
                                                <li key={i} className={(category && category.toUpperCase() === title) ? `active` : ``}>
                                                    <img src="../assets/img/blog/category_img/arrow.svg" alt="arrow.svg" />
                                                    <button onClick={(e) => filterOnclick(e)} style={{
                                                        background: "no-repeat",
                                                        outline: "none",
                                                        border: "none"
                                                    }} value={title.toLowerCase()}>{title}</button>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row >
            </Container >
        </div >
    )
}

export default React.memo(BlogCategory);